<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :row-key="row => row.id" :loading="loading"
      @selection-change="orderinvoiceListChange">
      <template slot="toolbar">
        <div style="width: 100%;">
          <el-steps :active="0" finish-status="success" simple style="margin-top: 20px">
            <el-step title="1、选择开票账单，点击申请开票"></el-step>
            <el-step title="2、确认开票信息和地址"></el-step>
            <el-step title="3、提交开票申请成功"></el-step>
          </el-steps>
          <div class="databox">
            <div>
              <div style="width: 40%">
                <p>已选开票金额（元）</p>
                <p class="yikai">
                  {{ choosePrice1 }}
                </p>
              </div>
              <div style="width: 40%">
                <p>待开票总金额（元）</p>
                <p class="daikai">{{ toDoPrice0 }}</p>
              </div>
              <div class="goright">
                <el-button @click="requestInvoice" size="small" type="primary">申请发票</el-button>

              </div>
            </div>
          </div>
          <el-form-item label="开票状态">
            <el-select style="width: 100px;" v-model="params.invoice_state" placeholder="请选择状态">
              <el-option v-for="(item, index) in statusList" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          </el-form-item>
          <el-form-item>
            温馨提示: 单张发票金额限额100万，索取金额超过100万将拆分成多张发票开具。
          </el-form-item>
          <el-form-item style="float: right;">
            <el-button @click="showPartFn(1)" type="primary" size="small">开票信息管理</el-button>
            <el-button @click="showPartFn(2)" type="primary" size="small">查看开票记录</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
        <el-table-column label="账单年月" width="160">
          <template slot-scope="{row}">{{ row.sn | sn_filter }}</template>
        </el-table-column>
        <el-table-column label="商品总金额" prop="goods_total" />
        <el-table-column label="运费总金额" prop="shop_freight_price" />
        <el-table-column label="账单总金额" prop="statement_total" />
        <el-table-column label="开票状态" prop="invoice_state">
          <template slot-scope="{row}">
            <span>{{ row.invoice_state == 0 ? "未开票" : (row.invoice_state == 1 ? "开票中" : "已开票") }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="getInfoDetail(row)">查看详情</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as reconciliationInvoices from "@/api/reconciliationInvoices";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import * as API_goods from "@/api/goods";
export default {
  name: 'reconciliationInvoices',
  components: { EnTableLayout },
  data () {
    return {
      idsList: [],//选中的ids
      toDoPrice0: 0, //待开票总金额
      choosePrice1: 0, //已选开票金额
      selectedData: [],//复选框选中的数据
      statusList: [
        { label: '全部', value: ' ' },
        { label: '未开票', value: 0 },
        { label: '开票中', value: 1 },
        { label: '已开票', value: 2 },

      ],//审核状态
      provinceList: [],
      time_range: '',//时间
      /** 列表loading状态 */
      loading: false,
      editVisible: false,//审核页
      txInfo: {},
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        invoice_state: ' '
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**状态 */
    status_filter: value => {
      switch (value) {
        case 0:
          return '待审核';
        case 1:
          return '已通过';
        default:
          return '已拒绝';
      }
    },
    sn_filter: value => {
      let str = value.split('')
      str.splice(4, 0, '年')
      let newValue = str.join('')
      newValue = newValue + '月'
      return newValue
    }

  },
  mounted () {
    this.GET_AccountList();//查询列表数据
    this.GET_ToDoPrice0();// 待开票总金额
    this.GET_ToDoPrice1();// 已选开票金额
  },
  methods: {
    // 查看开票记录
    showPartFn (val) {
      if (val === 1) {
        this.$router.push({ path: "/finance/Invoicemanger/Invoicemangerdetail" });
      } else {
        this.$router.push({
          path: "/finance/Invoicemanger/Invoicemangerjilu",
          query: {
            is: "4",
          },
        });
      }
    },
    /**复选框选中事件 */
    orderinvoiceListChange (val) {
      this.selectedData = val;
      let ids = [];
      val.forEach((item) => {
        ids.push(item.sn);
      });
      this.idsList = ids;
      this.GET_ToDoPrice0();
      this.GET_ToDoPrice1();
    },
    // 待开票总金额
    GET_ToDoPrice0 () {
      reconciliationInvoices.getWaitOrderTotalPrice({
        enterprise_invoice: 0
      }).then((res) => {
        this.toDoPrice0 = res;
      });
    },
    // 已选开票金额
    GET_ToDoPrice1 () {
      if (this.idsList.length > 0) {
        reconciliationInvoices.getWaitOrderTotalPrice({
          enterprise_invoice: 0,
          account_statement_sn_str: this.idsList,
        }).then((res) => {
          this.choosePrice1 = res;
        });
      } else {
        this.choosePrice1 = 0;
      }
    },
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**关闭审核认证弹窗 */
    cancel () {
      this.editVisible = false//审核页
    },
    /**打开详情页弹窗 */
    getInfoDetail (row = null) {
      this.$router.push({
        path: '/finance/reconciliationInvoices/detail',
        query: { sn: row.sn }
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    /**查询列表 */
    GET_AccountList () {
      this.loading = true;
      let params = {
        ...this.params
      }
      reconciliationInvoices.getWaitAccountOrderList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    },
    // 跳转方法
    async requestInvoice () {
      // 索取发票->跳转
      let arr = [],
        str = "",
        price = 0;
      let data = {
        is_all: null,
        order_ids: [],
      };
      price = this.choosePrice1
      console.log(this.selectedData)
      this.selectedData.map((item) => {
        arr.push(item.sn);
      });
      str = arr.join(",");
      console.log(arr)
      if (arr.length <= 0) {
        this.$message.error("请先选择开票订单");
        return;
      } else {
        data.is_all = 0;
        data.order_ids = arr;
      }
      if (price == 0) {
        this.$message.error("请保证勾选的账单金额总额大于0");
        return;
      }
      //是否可以申请开票 是否设置发票信息
      const invoicedata = await API_goods.getReceiptAddressCheck()
      if (!invoicedata) {
        this.$message.error("请设置开票信息");
        return;
      }
      API_goods.getWaitReceiptOrderListaddHistory({
        step: 1,
        account_statement_sn_str: data.order_ids,
        is_all: data.is_all,
        invoice_check: 1
      }).then((res) => {
        this.$router.push({
          path: "Invoicemanger/requestInvoice",
          query: { id: str, price },
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

.txInfoFrom {
  .el-form-item {
    margin-bottom: 0;
  }
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.databox {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px !important;

  >p {
    padding: 0;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 22px;
    color: #393c41;
  }

  >div {
    background: #ffffff;
    border-radius: 5px;
    width: 100%;

    div {
      display: inline-block;

      p {
        margin: 0;
        width: 100%;
        padding-left: 5%;
        text-align: left;
        line-height: 24px;
        border-right: 1px solid #dcdfe6;
      }
    }

    .goright {
      display: inline-block;
      padding-left: 4%;
      width: 19%;
    }
  }

  .yikai {
    color: #e3405b;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }

  .daikai {
    color: #5dbe2d;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }

  .noueb {
    border: none;
    margin-left: -7%;
  }
}

/deep/ .el-step.is-simple:not(:last-of-type) .el-step__title {
  max-width: 64%;
}
</style>
